import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import { renderPageSections } from '../renderPageSections';
import SuiteBannerSection from '../../organisms/SuiteBannerSection/SuiteBannerSection';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import useLocalize from '../../../hooks/useLocalize';

const SuitePage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanitySuitepage === null) {
    return null;
  }

  const data = useLocalize(rawData, locale);

  const suitePageProps = {
    id: data.sanitySuitepage._id,
    metadata: { ...data.sanitySuitepage._rawRoute, href },
    suiteType: data.sanitySuitepage.suiteType,
    suiteBannerSection: {
      ...data.sanitySuitepage._rawSuiteBannerSection,
      suiteType: data.sanitySuitepage.suiteType,
    },
    sections: data.sanitySuitepage._rawSections,
    bubbleCtaSection: data.sanitySuitepage._rawBubbleCtaSection,
  };

  return (
    <Layout
      menuColor="gray"
      locale={locale}
      sanityId={suitePageProps.id}
      {...suitePageProps.metadata}
      bubbleCtaData={suitePageProps.bubbleCtaSection}
    >
      <SuiteBannerSection {...suitePageProps.suiteBannerSection} />
      {renderPageSections(suitePageProps.sections, {
        suiteType: suitePageProps.suiteType,
      })}
      {suitePageProps.bubbleCtaSection && (
        <BubbleCtaSection {...suitePageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

SuitePage.propTypes = {
  data: PropTypes.shape({
    sanitySuitepage: {},
  }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
};

SuitePage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default SuitePage;

export const query = graphql`
  query($slug: String) {
    sanitySuitepage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawSuiteBannerSection(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
      suiteType
    }
  }
`;
